import { GetStaticProps } from "next";
import Link from "next/link";

// mui
import Grid from "@mui/material/Grid";

const Error404Page = () => {
  return (
    <Grid container alignItems="center" justifyContent="center" direction="column" sx={{ mt: 20 }}>
      <Grid item>
        <p>ページが見つかりませんでした</p>
        <p>
          <Link href="/" passHref>
            トップページに戻る
          </Link>
        </p>
      </Grid>
    </Grid>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {
      layout: "noLoginHeader",
    },
  };
};

export default Error404Page;
